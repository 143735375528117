import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import * as routes from '../../constants/routes';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    

    render() {
        const FooterBanner = <div>
            <div className="banner-image  wow slideInDown"  data-wow-duration="0.99s" data-wow-delay="0.99s"><img src="./images/shape-4.png" alt="TechInherit"/></div>

            <div className="container">
                <div className="section-footer-contact">
                    <div className="title  wow fadeInUp"  data-wow-duration="0.4s" data-wow-delay="0.2s">Ready to grab a drink?</div>
                    <Link to="/" className="btn  wow fadeInUp"  data-wow-duration="0.6s" data-wow-delay="0.5s">Drop us a line</Link>
                </div>
            </div>
    
        </div>
        
        return (
            <section className="section section-footer">
               {window.location.href.indexOf("contact") < -1 && FooterBanner}
                <div className="container">
                    <div className="copyright">
                        <div className="logo order-sm-1 order-md-1 order-lg-1 "><Link to="#"><img src="/images/logo.png" alt="TechInherit"/></Link></div>
                        <div className="text order-sm-4 order-md-4 order-lg-2">&copy; Copyright 2018. <Link to="/">TechInherit Pvt. Ltd.</Link></div>
                        <div className="links order-sm-3 order-md-3 order-lg-3">
                            <Link exact to={routes.ABOUT} className="nav-link">About</Link>
                            <Link exact to={routes.HOME} className="nav-link">Works</Link>
                            <Link to="/">Contact us</Link>
                            <Link to="/">Terms</Link>
                            <Link to="/">Privacy</Link>
                        </div>
                        <div className="social order-sm-2 order-md-2 order-lg-4">
                            <Link to="#"><i className="fa fa-facebook"></i></Link>
                            <Link to="#"><i className="fa fa-twitter"></i></Link>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}


export default Footer;
