import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import * as routes from '../../constants/routes';

class Navigation extends Component {
  render() {
    const Path = window.location.pathname === '/' ? '' : 'navbar-relative';
    return (
      <nav className={"main-navbar navbar navbar-expand-lg  wow fadeInDown " + Path} data-wow-duration="0.9s" data-wow-delay="1s">
        <div className="container">
          <Link className="navbar-brand" to={routes.HOME}><img src="/images/logo.png" alt="TechInherit" /></Link>
          <button className={this.props.navbarVisible ? 'navbar-toggler active' : 'navbar-toggler'} type="button" onClick={this.props.onClick}>
            <span className="navbar-toggle-icon"></span>
            <span className="navbar-toggle-icon"></span>
            <span className="navbar-toggle-icon"></span>
          </button>

          <div className={this.props.navbarVisible ? 'collapse navbar-collapse navbar-visible' : 'collapse navbar-collapse'}>
            <Link to="/" className="nav-logo d-lg-none"><img src="./images/logo.png" alt="TechInherit" /></Link>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink exact to={routes.ABOUT} className="nav-link" activeClassName="active">About</NavLink>
              </li>

              <li className="nav-item">
                <NavLink exact to={routes.CASE} className="nav-link">Case Studies</NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink exact to={routes.SERVICES} className="nav-link">Services</NavLink>
              </li>
              <li className="nav-item">
                <a href={routes.BLOGS} className="nav-link">Blogs</a>
              </li> */}

              <li className="nav-item">
                <NavLink exact to={routes.CONTACT} className="nav-link nav-btn btn">Get In Touch</NavLink>
              </li>

            </ul>

          </div>
        </div>
      </nav>
    )
  }
}


export default Navigation
