import React, { Component } from 'react';
import Banner from './Home/Banner';
import Partners from './Home/Partners';
import Technology from './Home/Technology';
import Services from './Home/Services';
import Testimonials from './Home/Testimonials';
import Portfolio from './Home/Portfolio';

class Home extends Component {
    render() {
        return (
            <div  className="body-container">
               <Banner/>
               <Partners /> 
               <Technology/>
               <Services/>
               <Testimonials/>
               <Portfolio/>
            </div>
        )
    }
}

export default Home;
