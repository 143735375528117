import React, { Component } from 'react';
import  {Link} from 'react-router-dom';

class CaseStudies extends Component{
	constructor(props){
		super(props);
		this.state={
			cases:[
				{
					image:'./images/about.jpg',
					logo:'./images/swift.png',
					slug:'/case/uffride',
					title:'Uffride Inc.',
					color:'#000',
					description:"ESLint is an open source JavaScript linting utility originally created by Nicholas C. Zakas in June 2013. Code linting is a type of static analysis that is frequently used to find problematic patterns or code that doesn't adhere to certain style guidelines."
				},
				{
					image:'./images/about-bg.jpg',
					logo:'./images/swift.png',
					slug:'/case/uffride',
					title:'Uffride Inc.',
					color:'orange',
					description:"Some quick example text to build on the card title and make up the bulk of the card's content."
				},
				{
					image:'./images/about.jpg',
					logo:'./images/swift.png',
					slug:'/case/uffride',
					title:'Uffride Inc.',
					color:'green',
					description:"Some quick example text to build on the card title and make up the bulk of the card's content."
				},
				{
					image:'./images/about-bg.jpg',
					logo:'./images/swift.png',
					slug:'/case/uffride',
					title:'Uffride Inc.',
					color:'#fff',
					description:"Some quick example text to build on the card title and make up the bulk of the card's content."
				}
			]
		}
	}

	handleCaseContent = () =>{
		console.log(this.state.cases);
		return this.state.cases.map((item,i)=>{
			const desc=(item.description.length > 100)?item.description.substr(0,100)+"...":item.description;
			return (
				<div className="col-lg-4" key={i}>
	    			<div className="card">
	    				<Link to={item.slug} className="card-img-top">
	    					<span className="card-image"><img src={item.image}  alt={item.title}/></span>
	    					<span className="card-logo" style={{backgroundColor:item.color}}>
								<img src={item.logo} />
							</span>
	    				</Link>
	    			
						<div className="card-body">
					    	<div className="card-content">
					    		<h5 className="card-title"><Link to={item.slug}>{item.title}</Link></h5>
					    		<p className="card-text">{desc}</p>
					    	</div>
					    	<Link to={item.slug} className="card-link">Learn More <i className="icon-arrow-right"></i></Link>	
					  	</div>
	    			</div>
	    		</div>
			);
		})
	}
	render(){
		return(
			<section className="page-cases">

				<div className="page-bg">
					<img src="./images/map-gray.png"/>

				</div>
				<div className="container page-content">
                    <h2 className="section-header wow fadeInUp text-center"  data-wow-duration="1s">
                        <small>Our Works</small>
                        Case Studies
                    </h2>
					<div className="page-cases-wrapper">
	                    <p>
	                    	Developing a competitive advantage calls for developing more intelligent software solutions.
	                    </p>

	                    <div className="card-wrapper">
	                    	<div className="row">
	                    		{this.handleCaseContent()}
	                    	</div>
	                    </div>
					</div>
                </div>


                
            </section>
		)
	}
}
export default CaseStudies;