import React, { Component } from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import WOW from 'wowjs';

class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false
    }
    this.scrollToTop = this.scrollToTop.bind(this);
  }
  componentDidMount() {
    new WOW.WOW().init();
    setTimeout(() => {
      this.setState({
        isActive: true

      });


    }, 1000)


  }
  scrollToTop() {
    scroll.scrollToTop();
  }


  render() {
    return (
      <div className={this.state.isActive ? 'banner banner-animate' : 'banner'}>
        <div className="container">
          <div className="text">
            <div className="text-wrapper">
              <h1 className="wow fadeInUp" data-wow-duration="0.5s">
                <span>We</span>
                Create Best Solution For You and Your Business
                            </h1>
              <div className="description  wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.2s">
                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                            </div>
              <div className="wow fadeInUp" data-wow-duration="0.7s" data-wow-delay="0.2s">
                <Link to="/" className="btn">Get in touch</Link> or <Link to="/" className="underline-link">View our Works</Link>
              </div>

              <div className="contact-details  wow fadeIn" data-wow-duration="1s" data-wow-delay="0.7s">
                <ul>
                  <li>
                    <div className="icon">
                      <img src="./images/phone.png" alt="phone" />
                    </div>
                    <div className="content">
                      <label>Call us:</label>9869263396 / 9803183517
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <img src="./images/envelope.png" alt="mail" />
                    </div>
                    <div className="content">
                      <label>Email us:</label>
                      techinheritinc@gmail.com
                                            </div>
                  </li>
                </ul>

                <div className="social">
                  <Link to="#"><i className="fa fa-facebook"></i></Link>
                  <Link to="#"><i className="fa fa-twitter"></i></Link>
                  <Link to="#"><i className="fa fa-google-plus"></i></Link>
                  <Link to="#"><i className="fa fa-dribbble"></i></Link>
                  <Link to="#"><i className="fa fa-medium"></i></Link>
                </div>
              </div>

            </div>
          </div>
          <div className="image">
            <div className="image-wrapper">
              <img src="./images/macbook.png" alt="Macbook" />
              <div className="shapes">

                <div className="shape-big">
                  <div className="shape-big-1"></div>
                  <div className="shape-big-2"></div>
                </div>
                <div className="shape-1"></div>
                <div className="shape-2"></div>
                <div className="shape-3"></div>
                <div className="shape-4"></div>
              </div>

            </div>
          </div>
          <div className="scroll-down">
            <Link to="scrollNext" spy={true} smooth={true} offset={-15} duration={500} className="scroll-down-content ">
              <img src="./images/mouse.png" alt="Mouse" />
              scroll down
                        </Link>
          </div>
        </div>
      </div>
    )
  }
}



export default Banner;
