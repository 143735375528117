import React, { Component } from 'react';
import Select from 'react-select';
import * as routes from '../../constants/routes';
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";

const options = [
  { label: "You\'re interested  in building a product with us", value: "Interested in product building" },
  { label: "You want to hire our engineer team", value: "Hire our Team" },
  { label: "You want to become an Inheritian", value: "Become an Inheritian" }
]

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    color: state.isSelected ? 'red' : 'blue',
    padding: 20,
  })
}

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
      captcha: "",
      selectedOption: null,
      error: {}
    };
  }

  handleSelectChange = (selectedOption) => {
    this.setState({
      selectedOption,
      subject: selectedOption.value
    });
  }

  onChange = (value) => {
    console.log(value);
  }

  handleValidation = (data) => {
    const error = {};
    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    if (!data.name) error.name = "Fullname is required";
    if (!data.email) error.email = "Email is required";
    if (!expression.test(String(data.email).toLowerCase())) error.email = "Invalid error format";
    if (!data.subject) error.subject = "Please choose subject";
    if (!data.message) error.message = "Message is required";
    if (!data.recaptcha) error.recaptcha = "Captch is required";
    return error;
  }

  handleChange = (e) => {
    const target = e.target.name;
    this.setState({
      [target]: e.target.value
    });

    console.log([target] + ":" + e.target.value);
  }
  handleCaptchaChange = (captcha) => {
    console.log(captcha);
  }
  handleSubmit = (e) => {
    e.preventDefault();
    // let error= Object.assign({}, this.state.error);
    let error = this.handleValidation(this.state);
    this.setState({ ...this.state.error, error }, () => {
      if (Object.keys(error).length == 0) {
        return true;
      }
    });
  }

  render() {
    let captcha;
    const { selectedOption } = this.state;
    const { onChange, handleCaptchaChange } = this;
    const { error } = this.state;
    return (
      <section className="page-contact">
        <div className="container">
          <h2 className="section-header wow fadeInUp" data-wow-duration="1s">
            <small>Get in touch with us</small>
            Contact Us
                    </h2>
        </div>
        <div className="image-banner">
          <img src="./images/contact-bg.png" />
          <div className="container image-banner-container">
            <div className="page-contact-banner">
              <div className="page-contact-text page-contact-info">
                <h3>Techinherit Pvt ltd</h3>
                <ul>
                  <li>
                    <i className="icon-phone"></i>
                    <div className="text">
                      <a href="tel:+9779803183517">+977 9803183517</a>
                    </div>
                    <div className="text">
                      <a href="tel:+9779869263396">+977  9869263396</a>
                    </div>
                  </li>
                  <li>
                    <i className="icon-envelope"></i>
                    <div className="text">
                      <a href="mailto:techinheritinc@gmail.com"> techinheritinc@gmail.com</a>
                    </div>
                  </li>
                  <li>
                    <i className="icon-location-pin"></i>
                    <div className="text">
                      <a href="#">Sankhamul, Baneshwor, Kathmandu, Nepal</a>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="page-contact-text page-contact-social">
                <h3>Find us at</h3>
                <div className="social-icons">
                  <a href="#"><i className="fa fa-facebook"></i></a>
                  <a href="#"><i className="fa fa-twitter"></i></a>
                  <a href="#"><i className="fa fa-google-plus"></i></a>
                  <a href="#"><i className="fa fa-linkedin"></i></a>
                  <a href="#"><i className="fa fa-skype"></i></a>
                </div>
                <p>Need help to locate us? Explore the map below</p>
              </div>
            </div>
          </div>
        </div>
        <div className="page-contact-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <h2>Want to hear from us?</h2>
                <p>
                  Please drop us your name, number or email, and a brief note about your project. We’ll get right back to you
                                </p>
                <form onSubmit={this.handleSubmit}>
                  <div className={error.name ? "form-group form-error" : "form-group"}>
                    <input type="text" name="name" className="form-control" placeholder="Fullname*" value={this.state.name} onChange={this.handleChange} />
                    {error.name && <label className="error">{error.name}</label>}
                  </div>
                  <div className="row">
                    <div className="col-lg-7">
                      <div className={error.email ? "form-group form-error" : "form-group"}>
                        <input type="text" name="email" className="form-control" placeholder="Email Address*" value={this.state.email} onChange={this.handleChange} />
                        {error.email && <label className="error">{error.email}</label>}
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div className="form-group">
                        <input type="text" name="phone" className="form-control" placeholder="Phone Number(optional)" value={this.state.phone} onChange={this.handleChange} />
                      </div>
                    </div>
                  </div>
                  <div className={error.subject ? "form-group form-error" : "form-group"}>
                    <Select
                      value={selectedOption}
                      onChange={this.handleSelectChange}
                      options={options}
                      className="selectPicker"
                      classNamePrefix="selectPicker"
                      placeholder="You are writing to us because"

                    />
                    {error.subject && <label className="error">{error.subject}</label>}
                  </div>
                  <div className={error.message ? "form-group form-error" : "form-group"}>
                    <textarea className="form-control" name="message" placeholder="Message" value={this.state.message} onChange={this.handleChange} />
                  </div>
                  <div className={error.recaptcha ? "form-group form-error" : "form-group"}>
                    <ReCAPTCHA
                      ref={(el) => { captcha = el; }}
                      sitekey="6Lepm5AUAAAAADapiR8qCvBiZtoGg2IpdGT9-_cI"
                      onChange={this.handleCaptchaChange}
                    />
                    {error.recaptcha && <label className="error">{error.recaptcha}</label>}
                  </div>
                  <div className="form-group">
                    <button type="submit" className="btn btn-submit">Send Message</button>
                  </div>
                </form>
              </div>
              <div className="col-lg-5">
                <div className="page-contact-map">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.097059606892!2d85.33163741506152!3d27.68339488280182!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb197031b54d45%3A0xb70e34c034e09c85!2sTechInherit+Pvt.+Ltd.!5e0!3m2!1sen!2snp!4v1549870860929" frameborder="0" allowfullscreen></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}


export default Contact;
