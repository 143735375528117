import React from 'react';


const TestimonialList = props => (
    <div className={props.newClass?'testimonial-item '+props.newClass:'testimonial-item'}>
        <div className="testimonial-header">
            <div className="image">
                <img src={props.data.img} alt="User"/>
            </div>
            <div className="text">
                <div className="name">{props.data.name}</div>
                <div className="position">{props.data.position}</div>
            </div>
        </div>
        <div className="testimonial-content">
            {props.data.description}
        </div>
    </div>

);



export default TestimonialList;
