import React from 'react';
import TestimonialList from './TestimonialList';

const Class = props => (
    <TestimonialList newClass={props.addClass} data={props.data}/>
);



export default Class;
