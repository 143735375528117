import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Tilt from 'react-tilt'

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <section className="page-about">
                <div className="container">
                   <div className="page-about-banner">
                        <div className="text">
                            <h3>About <span>TechInherit</span></h3>
                            <h1 className="about-header">
                            We are a family that
                            loves to have fun
                            </h1>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. 
                            </p>
                        </div>
                        <div className="image">
                            <Tilt className="Tilt" options={
                                    { 
                                        max : 35 ,
                                        perspective:    1000,
                                        scale:          1,
                                    }
                            }>
                                <div className="item">
                                    <img src="./images/about-bg.jpg"/>
                                </div>
                            </Tilt>
                        </div>
                   </div>
                   
                </div>
                <div className="page-about-bg">
                    <div className="page-about-features">
                    <div className="grid-item item-1" style={{backgroundImage:"url('./images/image-1.jpg')"}}>
                        <img src="./images/image-1.jpg"/>
                    </div>
                    <div className="grid-item item-2" style={{backgroundImage:"url('./images/image-2.jpg')"}}>
                        <img src="./images/image-2.jpg"/>
                    </div>
                    <div className="grid-item item-3" style={{backgroundImage:"url('./images/image-3.jpg')"}}>
                        <img src="./images/image-3.jpg"/>
                    </div>
                    <div className="grid-item item-4" style={{backgroundImage:"url('./images/image-4.jpg')"}}>
                        <img src="./images/image-4.jpg"/>
                    </div>
                    <div class="grid-item text">
                        <p>
                            Since 2010, we have been working with clients to imagine and build software that people love to use. And we think we’re different. TechInherit is founded on a commitment to customer happiness and pride in craftsmanship.
                        </p>
                    </div>


                    <ul className="features">
                        <li className="feature-item feature-1">
                            <div className="icon">
                                <img src="./images/digital-marketing.png"/>
                            </div>
                            <h3>Feature One</h3>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur  adipiscing elit, sed do eiusmod tempor 
                                incididunt ut labore et dolore magna aliqua. 
                            </p>
                        </li>
                        <li className="feature-item feature-2">
                            <div className="icon">
                                <img src="./images/web-development.png"/>
                            </div>
                            <h3>Feature Two</h3>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur  adipiscing elit, sed do eiusmod tempor 
                                incididunt ut labore et dolore magna aliqua. 
                            </p>
                        </li>
                        <li className="feature-item feature-3">
                            <div className="icon">
                                <img src="./images/web-hosting.png"/>
                            </div>
                            <h3>Feature Three</h3>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur  adipiscing elit, sed do eiusmod tempor 
                                incididunt ut labore et dolore magna aliqua. 
                            </p>
                        </li>
                        <li className="feature-item feature-4">
                            <div className="icon">
                                <img src="./images/project-management.png"/>
                            </div>
                            <h3>Feature Four</h3>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur  adipiscing elit, sed do eiusmod tempor 
                                incididunt ut labore et dolore magna aliqua. 
                            </p>
                        </li>
                        <li className="feature-item feature-5">
                            <div className="icon">
                                <img src="./images/app-development.png"/>
                            </div>
                            <h3>Feature Five</h3>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur  adipiscing elit, sed do eiusmod tempor 
                                incididunt ut labore et dolore magna aliqua. 
                            </p>
                        </li>
                       
                    </ul>
                </div>
                </div>
                <div className="page-about-description">
                    <div className="container">
                        <blockquote>
                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa ullam libero, recusandae voluptatem voluptates temporibus ut accusantium atque quas obcaecati fugiat autem minus nihil fuga. Deleniti dolores perferendis quos vero?
                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa ullam libero, recusandae voluptatem voluptates temporibus ut accusantium atque quas obcaecati fugiat autem minus nihil fuga. Deleniti dolores perferendis quos vero?
                            
                        </blockquote>
                    </div>
                </div>
            </section>
        );
    }
}



export default About;
