import React, { Component } from 'react';
import {Link} from 'react-router-dom';
// import Carousel from 'nuka-carousel';
import Slider from "react-slick";
import TestimonialItem from './Testimonial';
import CountUp , { /*startAnimation*/} from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
function SlickNext(...props){
    return (
        <div {...props} className="slick-arrow arrow-right"><i className="fa fa-angle-right"></i></div>
    );
}
function SlickPrev(...props){
    return (
        <div {...props}  className="slick-arrow arrow-left"><i className="fa fa-angle-left"></i></div>
    );
}
class Testimonials extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active:false,
            slideIndex:0,
            testimonialList:[
                {
                    img:'./images/kudzai.jpeg',
                    name:'Kudzai Mhou',
                    position:'Baracoin.io',
                    description:"Great team players, TechInherit has  great developers who completes task on time and has great suggestions."
                },
                {
                    img:'./images/joel.jpeg',
                    name:'Joel Heitor',
                    position:'',
                    description:"TechInherit has got what it takes to get Java EE jobs done and fast! They're great!"
                },
                {
                    img:'./images/user.png',
                    name:'Joseph Pang',
                    position:'skynet.brighttutor.sg',
                    description:"Freelancer is very prompt in making amendments, and will love to work with him again. They are open to suggestions during the whole course."
                },
               
            ]
        };
    }
    onVisibilityChange = isVisible => {
        if (isVisible) {
            console.log(isVisible);
          this.setState({active: true});
        }
      }
    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            arrows:false,
            autoplay:true,
            autoplaySpeed:5000,
            prevArrow:<SlickNext/>,
            nextArrow:<SlickPrev/>,
            slidesToShow: 1,
            slidesToScroll: 1,
          
          };
        return (
            <section className="section section-testimonials">
            <div className="banner-image   wow slideInLeft"  data-wow-duration="0.9s" data-wow-delay="0.9s"><img src="./images/shape-3.png" alt="Shape"/></div>
            <VisibilitySensor onChange={this.onVisibilityChange} offset={{
                    top:0
                }} scrollCheck={true}>
                    <div className="container">
                    <div className="counter-wrap   wow fadeInUp"  data-wow-duration="0.4s" data-wow-delay="0.4s">
                        <div className="row">
                            <div className="col-6 col-sm-3 col-md-3">
                                <div className="counter">
                                    <div className="number"><span><CountUp end={this.state.active?200:0} /></span>+</div>
                                    Projects Completed
                                </div>
                            </div>
                            <div className="col-6 col-sm-3 col-md-3">
                                <div className="counter">
                                    <div className="number"><span><CountUp end={this.state.active?100:0} /></span>+</div>
                                    Happy Clients
                                </div>
                            </div>
                            <div className="col-6 col-sm-3 col-md-3">
                                <div className="counter">
                                    <div className="number"><span><CountUp end={this.state.active?99:0} /></span>%</div>
                                    Job Success Rate
                                </div>
                            </div>
                            <div className="col-6 col-sm-3 col-md-3">
                                <div className="counter">
                                    <div className="number"><span><CountUp end={this.state.active?10:0} /></span>+</div>
                                   Team Members
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-5">
                            
                            <h2 className="section-header   wow fadeInUp"  data-wow-duration="0.5s" data-wow-delay="0.4s">
                                <small>Why clients trust us?</small>
                                Testimonials
                            </h2>

                            <div className="section-testimonials-container   wow fadeInUp"  data-wow-duration="0.6s" data-wow-delay="0.4s">
                                <div className="text">
                                    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. 
                                </div>
                                <Link to="#" className="btn">Read All Reviews</Link>
                            </div>

                        </div>
                        <div className="col-md-7">
                            <div className="testimonial-slider-wrapper   wow fadeInUp"  data-wow-duration="0.8s" data-wow-delay="0.4s">
                                <div className="testimonial-slider">
                                    <Slider {...settings}>
                                        {
                                            this.state.testimonialList.map((val,index)=>{
                                              
                                                return <TestimonialItem key={index} addClass={this.state.slideIndex === index ? 'active' : ''} data={val}/>
                                            })
                                        }
                                    </Slider>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </VisibilitySensor>
            </section>
        );
    }
}

export default Testimonials;
