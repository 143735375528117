import React, { Component } from 'react';
import WOW from 'wowjs';
class Services extends Component {
    constructor(props) {
        super(props);
        this.state ={
            services:[{
                title:"Web Development", 
                image:'./images/web-development.png', 
                list:[
                    'Website Mockups',
                    'Responsive Web Design',
                    'Bootstrap or custom css',
                    'Custom CMS',
                    'Wordpress',
                    'Backend Development',
                    'API Development'
                ]
            },
            {
                title:"Search Engine Optimization", 
                image:'./images/seo.png', 
                list:[
                    'Include a site map page',
                    'SEO-friendly URLs',
                    'Unique and relevant title and meta description ',
                    'Diversify traffic sources'
                ]
            },
            {
                title:"Project Management", 
                image:'./images/project-management.png', 
                list:[
                    'Time and cost control',
                    'Contracting legal support',
                    'Risk analysis',
                    'Time planning',
                    'Requirement/operational analysis',
                    'Project programming'
                ]
            },
            {
                title:"App Development", 
                image:'./images/app-development.png', 
                list:[
                    'Sketch Mockups',
                    'Android App Development',
                    'iOS App Development',
                    'React Native App Development',
                    'Hybrid App Development'
                ]
            },
            {
                title:"Web Hosting", 
                image:'./images/web-hosting.png', 
                list:[
                    'Include a site map page',
                    'SEO-friendly URLs',
                    'Unique and relevant title and meta description',
                    'Diversify traffic sources'
                ]
            },

            {
                title:"Digital Marketing", 
                image:'./images/digital-marketing.png', 
                list:[
                    'Facebook / Instagram Marketing',
                    'Email Marketing',
                    'Content Marketing'
                ]
            },

            ]};
    }
    componentDidMount(){
        new WOW.WOW().init();
    }

    render() {
        return (
            <section className="section section-services">
                <div className="container">
                    <h2 className="section-header  wow fadeInUp"  data-wow-duration="0.5s">
                        <small>What we do?</small>
                        Services we provide.
                    </h2>

                    <div className="section-services-lists">
                        <div className="row">
                            {this.state.services.map((item,i)=>{
                                const time=0.5 * ((i+1)*0.7);
                                console.log(time);
                                return(
                                    <div className="col-sm-6  col-md-6 col-lg-4  wow fadeInUp" key={i} data-wow-duration={time +"s"} data-wow-delay="0.3s">
                                        <div className="item">
                                            <figure className="image">
                                                <img src={item.image} alt="Featured"/>
                                            </figure>
                                            <div className="text">
                                                <h4>{item.title}</h4>
                                                <ul>
                                                    {item.list.map((li,key)=>{
                                                        return <li key={key}>{li}</li>
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div> 

                </div>
            </section>
        );
    }
}


export default Services;
