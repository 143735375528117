import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Slider from "react-slick";


class Portfolio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projects:[
                {target:"#", image:'./images/portfolio-1.png' },
                {target:"#", image:'./images/portfolio-2.png' },
                {target:"#", image:'./images/portfolio-3.png' },
                {target:"#", image:'./images/portfolio-4.png' },
                {target:"#", image:'./images/portfolio-5.png' },
                {target:"#", image:'./images/portfolio-6.png' },
            ]
        };
    }

    render() {
        var settings = {
            dots: false,
            arrows:false,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay:true,
            autoplaySpeed:1000,
            pauseOnHover:true,
            easing:'linear',
            initialSlide: 0,
            responsive: [
              {
                breakpoint: 991,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,

                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
          };
        return (
           <section className="section section-portfolio   wow fadeIn"  data-wow-duration="0.8s" data-wow-delay="0.5s">
                <div className="section-portfolio-container">
                    <div className="container">
                        <div className="d-flex justify-content-between">
                            <h2 className="section-header">
                                <small>Projects Completed</small>
                                Products
                            </h2>
                            <div className="button">
                                <Link to="#" className="btn">View Portfolio</Link>
                            </div>
                        </div>
                    </div>
                    
                    <div className="section-portfolio-carousel">
                        <Slider {...settings}>
                            {this.state.projects.map((item,i)=>{
                                return (
                                    <div className="item" key={i}>
                                        <div className="image">
                                            <Link to={item.target}><img src={item.image} alt="user"/></Link>
                                        </div>
                                    </div>
                                );
                            })}
                        </Slider>
                    </div>
                </div>
           </section>
        );
    }
}

export default Portfolio;
