import React, { Component } from 'react'
import {BrowserRouter as Router, Route} from 'react-router-dom';
import Navigation from './components/common/Navigation';
import Footer from './components/common/Footer';
import Home from './components/views/Home';
import About from './components/views/About';
import Contact from './components/views/Contact';
import Cases from './components/views/CaseStudies';
import SingleCase from './components/views/SingleCase';



class Root extends Component {
    constructor(props) {
        super(props)
        this.state = {
            navbarVisible:false
        }
    }

    toggleNavbar = () => {
        return (
            this.setState({
                navbarVisible:!this.state.navbarVisible
            })
        );
    }

    render() {
        return (
            <Router>
                <div className={this.state.navbarVisible?'navbar-show body-wrapper':'body-wrapper'}>
                    <Navigation navbarVisible={this.state.navbarVisible} onClick={this.toggleNavbar}/>
                    <Route exact path="/" component={Home}/>
                    <Route exact path="/about" component={About}/>
                    <Route exact path="/contact" component={Contact}/>
                    <Route exact path="/cases" component={Cases}/>
                    <Route exact path="/case/:name" component={SingleCase}/>

                    <Footer/>
                </div>
            </Router>
        )
    }
}



export default Root 
