import React, {Component} from 'react';
import  * as routes  from '../../constants/routes';
import  {Link} from 'react-router-dom';

export default class SingleCase extends Component{
	render(){
		
		return(
			<section className="page-case case-details">
				<div className="case-details-banner">

					<img src='/images/contact-bg.png'/>
			
					
	                <div className="container">
	                    <h2 className="section-header wow fadeInUp text-center"  data-wow-duration="1s">
	                        <small>Case Study</small>
	                        Your Project Name Goes Here.
	                    </h2>
	                </div>
                </div>
             </section>
		);
	}
}