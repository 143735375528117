import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import WOW from 'wowjs';
class Technology extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount(){
        new WOW.WOW().init();
    }

    render() {
        return (
            <section className="section section-technology">
                <div className="banner-image  wow slideInRight"  data-wow-duration="0.99s" data-wow-delay="0.99s"><img src="./images/shape-2.png" alt="Shape"/></div>
               <div className="container">
              
                    <h2 className="section-header wow fadeInUp"  data-wow-duration="1s">
                        <small>Ready for the world</small>
                        Technology We Inherited
                    </h2>

               </div>
               <div className="section-technology-container container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="video   wow fadeInLeft"  data-wow-duration="0.4s" data-wow-delay="0.4s">
                                <div className="embed-responsive embed-responsive-16by9">
                                    <video className="embed-responsive-item" autoPlay='true' muted loop poster="./images/coding.jpg">
                                        <source src="./images/Words.mp4" type="video/mp4"></source>
                                        <source src="./images/Words.webm" type="video/webm"></source>
                                    </video>
                                </div>

                                <div className="technology-icons">
                                    <div className="item x1"><img src="./images/react.png" alt="React"/></div>
                                    <div className="item x2"><img src="./images/aws.png" alt="AWS"/></div>
                                    <div className="item x3"><img src="./images/spring-boot.png" alt="Spring Boot"/></div>
                                    <div className="item x4"><img src="./images/laravel.png" alt="Laravel"/></div>
                                    <div className="item x5"><img src="./images/sketch.png" alt="Sketch"/></div>
                                    <div className="item x6"><img src="./images/android.png" alt="Android"/></div>
                                    <div className="item x7"><img src="./images/swift.png" alt="Swfit"/></div>
                                    <div className="item x8"><img src="./images/photoshop.png" alt="Photoshop"/></div>
                                    <div className="item x9"><img src="./images/wordpress.png" alt="Wordpress"/></div>
                                    <div className="item x10"><img src="./images/android.png" alt="Android"/></div>
                                    <div className="item x11"><img src="./images/swift.png" alt="Swift"/></div>
                                    <div className="item x12"><img src="./images/photoshop.png" alt="Photoshop"/></div>
                                    <div className="item x13"><img src="./images/wordpress.png" alt="Wordpress"/></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="text   wow fadeInRight"  data-wow-duration="0.6s" data-wow-delay="0.6s">
                                <p>
                                    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. 
                                </p>
                                <p>
                                    The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as 
                                    opposed to using 'Content here, content here', making it look like readable English.
                                </p>
                                <Link  to="#" className="btn">Projects Completed</Link>
                            </div>
                        </div>
                    </div>
               </div>
            </section>
        );
    }
}

export default Technology;
