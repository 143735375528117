import React, { Component } from 'react';
import WOW from 'wowjs';
import { Element,/* animateScroll as scroll, scrollSpy, scroller*/ } from 'react-scroll';

export default class Partners extends Component{

	componentDidMount(){
        new WOW.WOW().init();
    }
	render(){
		return(
			<Element name="scrollNext" className="element">
				<section className="section-partner wow fadeInUp" data-wow-duration="0.5s">
					<div className="container">
						<div className="text-center">
							<h2>Trusted By</h2>
						</div>
						<ul className="section-partner-list">
							<li><img src="./images/chameleon.png" alt="Chameleon"/></li>
							<li><img src="./images/uff-ride.png" alt="Uff Ride"/></li>
							<li><img src="./images/applopers.png" alt="Applopers"/></li>
							<li><img src="./images/epitome.png" alt="Epitome"/></li>
							<li><img src="./images/prabidhilabs.png" alt="PrabodhiLabs"/></li>
						</ul>
					</div>
				</section>
			</Element>
		);
	}
}